
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
/* font-family: "Rubik", serif; */

.fonttable{
  font-family: "Noto Sans Thai", sans-serif !important;
}

.header-style{
width: 100%;
background-color: #343A40;
border: 1px solid #e9ebec9a;
color: white;
font-family: "Noto Sans Thai", sans-serif !important;
}
.cell-style{
  border: 1px solid #e9ebec9a;
  outline: none;
  font-family: "Noto Sans Thai", sans-serif !important;
}
.search{
  display: flex;
  justify-content: end;
  width: 100%;
  margin: 2% 0;
}
.optioncouse{
  display: flex;
  height: auto;
  width: 20%;
}
.inputsubmitcouse{
  height: auto;
  width: 20%;
}
.inputsubmitscouse input{
  padding: 10%;
}

@media screen and (min-width: 1024px) {
  .input{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: end;
  }
    /*  ตัวinput  */
  .inputsearch{
    display: flex;
    height: 100%;
    width: 35%;
    justify-content: end;

  }
 
  .inputsearch input{
    width: 100%;
    border: 1px solid transparent;
    background-color: #F4F6F9;
    padding: 3% 5%;
    font-family: "Noto Sans Thai", serif;
    border-radius: 0 10px 10px 0;
    font-size: 1.3vw;
  }

  .searchimage{
    display: flex;
    align-items: center;
    justify-content: end;
    width: 15%;
    height: auto;
    border-radius:10px 0 0 10px;
    background-color: #F4F6F9;
  }
  .searchimage img{
    opacity: 70%;
  }

  /* ตัวselect  */
  .option {
    width:25%;
    margin: 0 2%;
  }
  .option select {
    height: 100%;
    width: 100%;
    padding: 0 10%;
    font-family: "Noto Sans Thai", serif;
    color: #2d3748;
    border: 1px solid rgba(128, 128, 128, 0.24);
    border-radius: 5px;
    font-size: 1.3vw;
    appearance: none;
    background-image: url("../image/downarrow.svg");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto;
  }
nav{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8vh;
  margin:2% 0;
}
ul{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 35%;
}
li{
  display: flex;
  justify-content: center;
  align-items: center;
 height: 60%;
 width: 20%;
 transition: 0.5s;
 font-size: 1.3vw;
}
ul button{
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  font-family: "Noto Sans Thai", sans-serif;
  padding: 2px 0 0 0;

}

}
@media screen and (min-width: 1440px) {

  .inputsearch{
    display: flex;
    height: 100%;
    width: 35%;
    justify-content: end;

  }
 
  .inputsearch input{
    width: 100%;
    padding: 2% 5%;
    border-radius: 0 5px 5px 0;
    font-size: 1rem;
  }

  .searchimage{
    width: 10%;
    border-radius:5px 0 0 5px;

  }
  .searchimage img{
    height: 50%;
    opacity: 70%;
  }

  /* ตัวselect  */
  .option {
    width:25%;
    margin: 0% 2%;
  }
  .option select {
    height: 100%;
    width: 100%;
    padding: 0 10%;

    font-size: 1vw;

  }

  ul{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 30%;
  }
  li{
    display: flex;
    justify-content: center;
    align-items: center;
   height:80%;
   width: 15%;
   transition: 0.5s;
   font-size: 1vw;

  }
  ul button{
    display: flex;
    width: auto;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    font-family: "Noto Sans Thai", sans-serif;
    padding: 2px 0 0 0;
  
  }
 
}

@media screen and (min-width: 1500px) {
  .inputsearch{
    display: flex;
    height: 100%;
    width: 30%;
    justify-content: end;

  }
  .search{
    margin: 1.5% 0;
  }
 
  .inputsearch input{
    width: 100%;
    padding: 2% 5%;
    border-radius: 0 5px 5px 0;
    font-size: 1rem;
  }


  /* ตัวselect  */
  .option {
    width:20%;
    margin: 0% 2%;
  }
  .option select {
    font-size: 0.8vw;

  }
  
  ul{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 20%;
  }
  li{
    display: flex;
    justify-content: center;
    align-items: center;
   height:70%;
   width: 20%;
   transition: 0.5s;
   font-size:0.7vw;

  }
  ul button{
    display: flex;
    width: auto;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    font-family: "Noto Sans Thai", sans-serif;
    padding: 2px 0 0 0;
  
  }
}
.custom-select {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  font-family: "Noto Sans Thai", sans-serif;
}

.select-label {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #333;
  font-size: 14px;
  pointer-events: none; /* ป้องกันการคลิกที่ label */
}

.custom-select select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* ซ่อน select แต่ยังใช้งานได้ */
  cursor: pointer;
}
