@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
/* font-family: "Rubik", serif; */


@media screen and (min-width: 320px) {
.container{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: radial-gradient(
    70.71% 70.71% at 50% 50%,
    #12295b 0%,
    #09072b 70%
  );
  padding:10% 5%;
}
.gridfooter{
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  color: white;
  font-family: "Prompt", serif; 
  font-weight: 600;
}
.gridfooter h3{
  margin: 10% 0;
  font-weight: 600;
}
.gridfooter p{
  font-size: 3vw;
  margin: 5% 0;
  font-weight: 400;
  line-height: 200%;
}
.footercontent{
  margin: 15% 0;
}
.emailphone{
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.email{
display: flex;
}
.email p{
  margin: 0 2%;
}

.emailleft{
  color: rgba(210, 35, 41, 1);
}
.phone{
  display: flex;
}
.phone p{
  margin: 0 2%;
}
.phoneleft{
  color: rgba(210, 35, 41, 1);
}
.iconcontact{
  margin: 5% 0;
}
.iconcontact img{
  margin-right:10% ;
  cursor: pointer;
}
.link{
  border: none;
  color: white;
  text-decoration: none;
}
}
@media screen and (min-width: 768px) {
  .gridfooter h3{
    margin:8% 0;
  }
  .gridfooter p{
    font-size: 2.5vw;
    margin:0;
  }
  .footercontent{
    margin: 8% 0;
  }

  .iconcontact{
    height: auto;
    width: 100%;
    margin: 5% 0;
  }
  .iconcontact img{
    height: auto;
    width: 10%;
    margin-right:5% ;
  }
  .emailleft{
    padding-right: 3%;
  }
  .phoneleft{
    padding-right: 3%;
  }
}
@media screen and (min-width: 1024px) {
  .gridfooter h3{
    margin:4% 0;
  }
  .gridfooter p{
    font-size: 1.5vw;
    margin:0;
  }
  .footercontent{
    margin: 8% 0;
  }

  .iconcontact{
    height: auto;
    width: 100%;
    margin: 3% 0;
  }
  .iconcontact img{
    width: 6%;
    margin-right:2.5% ;
  }
}
@media screen and (min-width: 1440px) {
  .container{
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    padding: 4%;
  }
  .footerlogo{
    display: flex;
    width: 20%;
    height: auto;
    justify-content: center;
    align-items: start;
    padding: 3% 0;
  }
  .footerlogo img{
    height:auto;
    width: 30%;
  }
  .gridfooter{
    width: 70%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .gridfooter p{
    font-size: 1.2rem;
    margin:0;
    font-weight: 200;
  }
  .iconcontact img{
    width: 10%;
    margin-right:5% ;
  }
  .emailphone{
    margin-top: 5%;
  }
}
