@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
/* font-family: "Rubik", serif; */
@media screen and (min-width: 320px) {
  img.background {
    position: absolute;
    z-index: -1;
    right: 0;
    opacity: 20%;
  }
  .container {
    display: flex;
    padding: 0 0%;
    height: auto;
    width: 100%;
    overflow-x: hidden;
  }
  .header {
    display: flex;
    height: auto;
    width: 100%;
    color: #110229;
    font-family: "IBM Plex Sans Thai", serif;
    font-weight: 700;
    padding: 0 7%;
    justify-content: start;
  }
  .header h1 {
    font-size: 6vw;
    font-weight: 700;
  }
  .cardimg {
    display: flex;
    height: auto;
    justify-content: space-around;
    width: 100%;
    overflow-x: scroll;
    margin: 5%;
    position: relative;
  }
  .cardimg img {
    height: auto;
    width: 40%;
    margin: 0 1.5%;
    object-fit: cover;
  }
}
@media screen and (min-width: 768px) {
  img.background {
    display: none;
  }
  .header h1 {
    font-size: 4vw;
    margin: 0;
  }
}
@media screen and (min-width: 900px) {
  .header {
    padding: 0 2%;
  }
  .header h1 {
    font-size: 3vw;
    font-weight: 700;
  }
  .card {
    display: flex;
    height: auto;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
  }
  .cardimg {
    display: flex;
    height: auto;
    justify-content: space-around;
    width: 100%;
    overflow-x: hidden;
    margin: 3% 0;
  }
  .cardimg img {
    height: auto;
    width: 30%;
    object-fit: cover;
  }
}
@media screen and (min-width: 1440px) {
  .header h1 {
    font-size: 3rem;
    font-weight: 700;
  }
  .card {
    padding: 0 10%;
  }
  .cardimg img {
    height: auto;
    min-width: 2rem;
    max-width: 30rem;
    object-fit: cover;
  }
}
.cardimg::-webkit-scrollbar {
  /* delete display ของ scroll แต่ ยังใช้งานได้ (เปลี่ยนชื่อด้านหน้าด้วยตามclassนั้น)*/
  display: none;
}
