@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
/* font-family: "Rubik", serif; */
/* font-family: "Exo 2", serif; */


@media screen and (min-width:320px) {
    .container{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .contact{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 5%;
        
    }
    .header{
        height: auto;
        width: 100%;
    }
    .header h1{
        font-family: "Exo 2", serif;
        font-weight: 600;
        color: #182135;
        font-size: 5.5vw;
    }
    .header h3{
        font-family: "Noto Sans Thai", serif;
        font-weight: 400;
        font-size: 4.5vw;
    }
    .time{
        display: flex;
        font-family: "Noto Sans Thai", serif;
        font-weight: 400;
        font-size: 3.7vw;
        color: #6C6565; 
        padding: 0 3%;
        margin: 3% 0;
    }
    .time p{
        margin: 0;
    }
    .time .dot{
        margin: 0 5%;
    }
    .iconandcontact{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
    }  
    .contacticon{
        display: flex;
        height: auto;
        width: 100%;
        font-family: "exo 2", serif;
        font-weight: 400;
        font-size: 4vw;
        color:#656D7D;
        align-items: center;
        margin: 5% 0;
        font-family: "Noto Sans Thai", serif;
    }
    .contacticon p{
        margin: 0 3%;
    }
    .emailtel{
        display: flex;
        height: auto;
        flex-direction: column;
        width: 100%;
      
    }
    .map{
        display: flex;
        height: 60vh;
        width: 100%;
        justify-content: center;
    }
    iframe{
        height: auto;
        width: 100%;
    }

}
@media screen and (min-width:550px) {
    .contact{
        padding: 10%;
    }
    .header h1{
        font-size: 4vw;
    }
    .header h3{
        font-size: 3.2vw;
    }
   
    .contacticon{
        font-size: 2.8vw;
    }
    .time{
        display: flex;
        font-family: "Noto Sans Thai", serif;
        font-weight: 400;
        font-size: 2.3vw;
        color: #6C6565; 
        padding: 0 2%;
        margin: 3% 0;
    }
}
@media screen and (min-width:768px) {
    .contact{
        padding: 15%;
    }
    .header h1{
        font-size: 3.5vw;
    }
    .header h3{
        font-size: 2.8vw;
    }

    .contacticon{
        font-size: 2.2vw;
    }

}
@media screen and (min-width:1024px) {
    .container{
        flex-direction: row;
    }
    .contact{
        width: 50%;
        padding: 5%;
    }

    .header h1{
        font-size: 2.5vw;
    }
    .header h3{
        font-size: 2.2vw;
    }
  
    .contacticon{
        font-size: 1.5vw;
    }
    .emailtel{
        flex-direction: row;
    }
    .time{
        display: flex;
        font-family: "Noto Sans Thai", serif;
        font-weight: 400;
        font-size: 1.5vw;
        color: #6C6565; 
        padding: 0 2%;
        margin: 3% 0;
    }
    .map{
        height: auto;
        align-items: center;
        width: 50%;
        padding: 1% 3%;
    }
    iframe{
        height: 90%;
        width: 100%;
        border-radius: 20px;
    }

}
@media screen and (min-width:1440px) {
 .contact{
    padding: 2%;
 }
 .container{
    padding: 0 8%;
 }
 .contact{
    width: 50%;
    padding: 5%;
}

.header h1{
    font-size: 2rem;
}
.header h3{
    font-size: 2rem;
}
.time{
    display: flex;
    font-family: "Noto Sans Thai", serif;
    font-weight: 400;
    font-size: 1.1rem;
    color: #6C6565; 
    padding: 0 2%;
    margin: 3% 0;
}
.contacticon{
    font-size:1.2rem;
}
iframe{
    min-height: 40rem;
    max-height: 20rem;
    min-width: 20rem;
    max-width: 40rem;
}
}

