@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
/* font-family: "Rubik", serif; */
@media screen and (min-width: 320px) {
.container{
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10% 0;
}
.portfoliotext{
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: column;
  font-family: "Noto Sans Thai", serif;
  color: #170550;
  font-size: 3.5vw;
  padding: 0 10%;
}
.portfoliotext p{
  margin:2%;
}
.portfoliotext img{
  height: 2vh;
}
.portfoliotext h1{
  font-family:"Noto Sans Thai", serif;
  font-weight: 700;
  color: #170550;
  font-size: auto;
  margin:0;
}

.background{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  width: 100%;
  background-color:#170550 ;
  position: absolute;
  z-index: -1;
}
.portfoliogridimage{
  display: flex;
  height: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 10% 0;
}
.gridcontent{
  display: flex;
  height: 40vh;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.grid1{
display: flex;
flex-direction: column;
height: 100%;
width: 30%;
margin: 1%;
justify-content: center;
} 

.grid2{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 30%;
  justify-content: center;
  margin: 1%;
  padding-top: 8%;
}
.grid3{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 30%;
  margin: 1%;
  padding-top: 8%;
}

/* Image custom size */
img.port1 {
 height: 40%;
 width: 100%;
 object-fit: cover;
 margin-bottom: 6%;
 border-radius: 5px;
}
img.port2 {
  height: 40%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;

}
img.port3 {
  height: 40%;
  width: 100%;
  object-fit: cover;
  margin-bottom: 6%;
  border-radius: 5px;
}
img.port4 {
  height:50%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}img.port5 {
  height: 40%;
 width: 100%;
 object-fit: cover;
 border-radius: 5px;
 margin-bottom: 6%;
}
img.port6 {
  height: 60%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}


}
@media screen and (min-width: 768px) {
  .container{
    margin: 8% 0;
  }
  .gridcontent{
    display: flex;
    height: 60vh;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .portfoliotext{
    display: flex;
    height: auto;
    width: 100%;
    flex-direction: column;
    font-family: "Noto Sans Thai", serif;
    color: #170550;
    font-size: 3vw;
    padding: 0 10%;
  }
  .portfoliotext p{
    margin:2%;
  }
  .portfoliotext h1{
    font-family:"Noto Sans Thai", serif;
    font-weight: 800;
    color: #170550;
    font-size: 6vw;
    margin:0;
  }
  .portfoliotext img{
    height: 3vh;
  }
  .background{
    height: 25vh;
  }
}
@media screen and (min-width: 1024px) {
.container{
  flex-direction: row;
  margin: 0;
}
.portfoliotext{
  display: flex;
  height: 100%;
  width: 40%;
  flex-direction: column;
  align-items: left;
  padding: 0; 
  font-family: "Noto Sans Thai", serif;
  font-size: 1vw;
  color: #12295B;
  margin-top:15%;
  padding-left: 5%;
}
.portfoliotext p{
  font-size: 1.5vw;
  margin: 0;
  margin-bottom:3% ;
}
.portfoliotext h1{
  font-size: 3.5vw;
  color: #12295B;
  font-weight: 750;
  font-family:"Noto Sans Thai", serif;
}
.portfoliogridimage{
  width: 55%;
}
.background {
  height: 10rem;
  width:109%;
  position: absolute;
  background-color: #08062a;
  z-index: -1;
}
.portfoliotext img{
  height: 2vh;
}
}
@media screen and (min-width: 1440px) {
 .container{
  margin: 0;
 }
 .portfoliotext p{
  font-size:1.5rem;

}
.portfoliotext h1{
  font-size:3.5rem;

}
 .portfoliotext{
  padding-left: 0;
  margin-top:12%;
 }
 .portfoliotext img{
  height: 3vh;
  width: 80%;
 }
 .background{
  height: 30vh;
}
.gridcontent{
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

img.port1 {
  height: 17rem;
  max-width: 19.5rem;
  object-fit: cover;
  margin-bottom: 6%;
  border-radius: 15px;
 }
 img.port2 {
  height: 17rem;
  max-width: 19.5rem;
   object-fit: cover;
   border-radius: 15px;
 
 }
 img.port3 {
  height: 17rem;
  max-width: 19.5rem;
   object-fit: cover;
   margin-bottom: 6%;
   border-radius: 15px;
 }
 img.port4 {
  height: 19rem;
  max-width: 19.5rem;
   object-fit: cover;
   border-radius: 15px;
 }img.port5 {
  height: 17rem;
  max-width: 19.5rem;
  object-fit: cover;
  border-radius: 5px;
  border-radius: 15px;
 }
 img.port6 {
  height: 23rem;
   max-width: 19.5rem;
   object-fit: cover;
   border-radius: 15px;
 }
 .grid1{
  } 
  .grid2{
  }
  .grid3{
  }
}

