@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
/* font-family: "Rubik", serif; */
.container {
  display: flex;
  height: auto;
  width: 100%;
  background-image: url("../image/banner.jpg");
  background-attachment: cover;
  background-position: center;
  position: relative;
}

.background{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100%;
  width: 100%;
  background-color: #08062A80;
  z-index: 2;
  
}
.container img{
  position: absolute;
  bottom: 0;
  right: 0;
  height:100%;
  width: 100%;
  object-fit:cover;

}
.text{
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5%;
  text-align: center;
  font-family: "Noto Sans Thai", serif;
  font-weight: 400;
  line-height: 2;
  margin: 4% 0;

}
.text p{
  text-align: center;
  margin: 2% 0;
  font-size: 3.9vw;
}

.white{
  color: white;
  font-size: 6vw;
  font-family: "Prompt", serif;
  font-weight: 600;
}
.yellow{
  color: #FFE492;
  font-size: 5.5vw;
  font-family: "Inter", serif;
  font-weight: 800;
}
@media screen and (min-width: 500px) {
.text p{
  font-size: 3vw;
}
.white{
  font-size: 4vw;
}
.yellow{
  font-size: 3.5vw;
}
}


@media screen and (min-width: 768px) {
 .text p{
  font-size: 2vw;
  padding: 0 10%;
  text-align: center;
}
.text {
  margin: 0;
}
}
@media screen and (min-width: 1440px) {
  .container{
    height: 50vh;
    background-size: cover; /* ทำให้รูปภาพเต็มพื้นที่ */
    background-position: center; /* จัดตำแหน่งรูปภาพให้อยู่กลาง */
    background-repeat: no-repeat;
   }
   .text p{
    text-align: start;
    margin: 0;
  }
  
  .white{
    color: white;
    font-size: 3rem;
    font-family: "Prompt", serif;
    padding: 0 10%;
    font-weight: 600;
  }
  .yellow{
    color: #FFE492;
    font-size: 3rem;
    font-family: "Inter", serif;
    padding: 0 10%;
    font-weight: 800;
  }
  .text p{
    text-align: center;
    padding: 0 15%;
    font-size: 1.5rem;
   }
}

