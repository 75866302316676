@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap");
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
/* font-family: "Rubik", serif; */
/* font-family: "Exo 2", serif; */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");
@media screen and (min-width: 320px) {
  .allpagenation {
    display: flex;
    height: auto;
    justify-content: center;
    width: 100%;
    margin: 5% 0;
  }
  .pagination {
    display: flex;
    height: auto;
    justify-content: space-around;
    width: 800%;
  }
  .pagination button {
    border: none;
    background-color: transparent;
    font-family: "Noto Sans Thai", serif;
    font-size: 3vw;
    padding: 2% 3%;
    border-radius: 5px;
  }
  .activePage {
    background-color: #1e204e !important;
    color: white;
  }
  .container {
    height: auto;
    width: 100%;
    display: flex;
    padding: 0 5%;
    margin: 5% 0;
    flex-direction: column;
  }
  form {
    width: 100%;
  }
  .option {
    display: flex;
    width: 100%;
    margin: 2% 0;
    outline: none;
  }

  select {
    width: 100%;
    padding: 3% 3%;
    font-family: "Noto Sans Thai", serif;
    color: #2d3748;
    border: 1px solid rgba(128, 128, 128, 0.24);
    border-radius: 5px;
    font-size: 4vw;
    appearance: none;
    background-image: url("../image/downarrow.svg");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 2% auto;
    outline: none;
  }
  .inputsubmit {
    display: flex;
    width: 100%;
    justify-content: end;
    margin: 5% 0;
    position: relative;
  }
  .inputsubmit input {
    flex-grow: 1; /* ให้ input ขยายเต็มพื้นที่ที่เหลือ */
    width: 100%;
    padding: 3% 3%;
    border: 1px solid rgba(128, 128, 128, 0.24);
    border-radius: 5px;
    font-size: 4vw;
    background-image: url("../image/Icon-Search.png");
    background-repeat: no-repeat;
    background-position: right 0.5rem top 50%;
    background-size: 4.5% auto;
    font-family: "Noto Sans Thai", serif;
  }
  i {
    position: absolute;
    right: 5%;
    bottom: 25%;
    pointer-events: none; /* ไอคอนไม่รบกวนการคลิก */
    font-size: 18px; /* ขนาดไอคอน */
    color: #d3d0d0; /* สีของไอคอน */
  }
  .inputsubmit input::placeholder {
    font-family: "Noto Sans Thai", serif;
    color: #2d3748;
    font-size: 4vw;
  }

  .submitbouttonandclear {
    display: flex;
    justify-content: end;
    width: 100%;
    margin: 8% 0;
  }
  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    color: white;
    background-color: #09072b;
    border-radius: 5px;
    font-family: "Noto Sans Thai", serif;
    font-size: 4vw;
    font-weight: 400;
    cursor: pointer;
    border: 1px solid transparent;
  }
  .submit p {
    margin: 0;
  }

  .clear {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;
    margin: 0 3%;
    background-color: transparent;
    border: 1px solid #09072b;
    border-radius: 5px;
    font-family: "Noto Sans Thai", serif;
    cursor: pointer;
    font-size: 4vw;
  }
  .clear p {
    margin: 8%;
  }
  .cardcontainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: auto;
  }
  .couse {
    display: flex;
    min-height: 10vh;
    width: 100%;
    margin: 5% 0;
    border: 1px solid #dcdcdc;
  }
  .category {
    display: flex;
    height: 100%;
    align-items: center;
    width: 25%;
  }

  .detailall {
    display: flex;
    width: 75%;
    height: 100%;
    flex-direction: column;
    padding: 5%;
    font-family: "Noto Sans Thai", serif;
  }
  .border {
    display: flex;
    border: 2px none #e1e1e1;
    border-bottom-style: dashed;
    width: 100%;
    margin: 4% 0;
  }
  .detailall h5 {
    margin: 0;
    font-size: 3.5vw;
  }
  .detail {
    display: flex;
    align-items: center;
  }
  .imagebackground {
    height: auto;
    width: 25%;
  }
  .imagebackground img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .text li {
    font-size: 2.5vw;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* กำหนดให้แสดงแค่ 2 บรรทัด */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .button {
    display: flex;
    height: auto;
    width: 70%;
    justify-content: end;
    align-items: center;

  }
  .button button {
    height:100%;
    width: 80%;
    background-color: #f06548;
    border-radius: 5px;
    border: none;
    color: white;
    padding: 1%;
    font-size: 2.5vw;
    font-family: "Noto Sans Thai", serif;
    font-weight: 400;
    padding: 5%;
  }
}
@media screen and (min-width: 425px) {
  i {
    position: absolute;
    right: 5%;
    bottom: 30%;
    pointer-events: none; /* ไอคอนไม่รบกวนการคลิก */
    font-size: 18px; /* ขนาดไอคอน */
    color: #d3d0d0; /* สีของไอคอน */
  }

  select {
    padding: 2% 3%; /* สีของไอคอน */
    font-size: 3.5vw; /* สีของไอคอน */
  }

  .inputsubmit input {
    padding: 2% 3%;
    font-size: 3.5vw;
  }
  .inputsubmit input::placeholder {
    font-size: 3.5vw;
  }
  .submitbouttonandclear {
    margin: 4% 0;
  }

  .submit {
    width: 30%;
    font-size: 3.2vw;
  }
  .submit p {
    margin: 0;
  }

  .clear {
    width: 28%;
    font-size: 3.2vw;
  }
  .clear p {
    margin: 7% 0%;
  }
  .button button {
    height: 100%;
    width: 80%;
  }
}
@media screen and (min-width: 569px) {
}
@media screen and (min-width: 768px) {
  .inputsubmit input {
    background-image: url("../image/Icon-Search.png");
    background-repeat: no-repeat;
    background-position: right 0.8rem top 50%;
    background-size: 2.2% auto;
    font-family: "Noto Sans Thai", serif;
  }
  .inputsubmit input {
    padding: 2% 3%;
    font-size: 2vw;
    font-family: "Noto Sans Thai", serif;
  }
  select {
    font-size: 2vw;
  }
  button.clear p {
    margin: 1%;
    font-size: 2vw;
  }
  button.submit p {
    margin: 5% 0;
    font-size: 2vw;
  }
  .inputsubmit input::placeholder {
    font-size: 2vw;
  }

  .submitbouttonandclear {
    margin: 4% 0;
  }

  .namecouseandborder h5 {
    font-size: 3vw;
  }
  .text {
    font-size: 2vw;
  }
  .button {
    justify-content: end;
    width: 40%;
  }
  .button button {
    height: 100%;
    font-size: 2vw;
  }
  .allpagenation {
    display: flex;
    height: auto;
    justify-content: center;
    width: 100%;
    margin: 5% 0;
  }
  .pagination {
    display: flex;
    height: auto;
    justify-content: space-around;
    width: 45%;
  }
  .pagination button {
    border: none;
    background-color: transparent;
    font-family: "Noto Sans Thai", serif;
    font-size: 2vw;
    padding: 2% 3%;
    border-radius: 5px;
  }
  .activePage {
    background-color: #1e204e !important;
    color: white;
  }
  .button button {
    height:100%;
    width: 80%;
  }
}
