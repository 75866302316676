* {
  margin:  0;
  padding: 0;
  box-sizing: border-box;
}

@media screen and (min-width: 1440px) {
  .padding{
    padding:6% 0 0 0;
  }
}

