@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
/* font-family: "Rubik", serif; */
@media screen and (min-width: 320px) {
  .container {
    display: flex;
    height:auto;
    width: 100%;
    position: relative;
    overflow-x: hidden;
  }
  .container img {
    width: 100%;
    height: auto;
    object-fit: fill;
    aspect-ratio: 16/9
  }

  .carousel-item {
    height: 0px; /* กำหนดความสูงของสไลด์ */
  }
  
  .button{
    display: flex;
    position: absolute;
    color: white;
    z-index:99;
    width: 100%;
    left: 3%;
    height: 10%;
    bottom: 10%;
    align-items: end;
    
  }
  .button button{
    display: flex;
    height: 50%;
    width: 35%;
    border: none;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 3vw;
    font-family: "Noto Sans Thai", serif;
    color: white;
    font-weight: 400;
    border-radius: 6px;
    margin: 0.5%;
    padding: 5% 0%;
  }
  button.contact{
    background-color: white;
    display: flex;
    justify-content: space-around;
    color: #E90808;
    border: 1px solid #E90808;
    font-weight: 500;
    cursor: pointer;
    padding: 5% 0%;
   
  }
  button.desktopbutton{
    display: none;
  }
}
@media screen and (min-width: 550px) {
  .button{
    display: flex;
    position: absolute;
    color: white;
    z-index:99;
    width: 100%;
    left: 3%;
    height: 10%;
    bottom: 11%;
    align-items: end;
  }
  .container img{
    aspect-ratio: 16/9;
  }
  .button button{
    width: 30%;
    padding: 5% 0;
  
  }
  button.desktopbutton{
    display: none;
  }
}
@media screen and (min-width: 768px) {

button.desktopbutton{
  background-color: white;
  display: flex;
  width: 40%;
  justify-content: space-around;
  color: #E90808;
  border: 1px solid #E90808;
  font-weight: 500;
  padding: 3% 0;
  font-size: 1.6vw;
}
button.couse{
  padding: 3% 0;
  font-size: 1.7vw;
  width: 20%;
}

}

@media screen and (min-width: 1440px) {
  .container img{
    aspect-ratio: 16/7;
    object-fit: cover;
  }
  button.couse{
    padding: 2% 0;
    font-size: 1.3vw;
    width: 20%;
  }
  button.desktopbutton{
    width: 30%;
    font-size: 1.2vw;
    padding: 2% 0;
  }
}
@media screen and (min-width: 1920px) {
  .container img{
    aspect-ratio: 16/7;
    object-fit: cover;
  }
  .button{
    bottom: 8%;
    transition: 1s;
  }
  button.couse{
    padding: 1.7% 0;
    font-size: 1.3vw;
    width: 15%;
  }
  button.desktopbutton{
    width: 25%;
    font-size: 1vw;
    padding: 1.7% 0;
  }
}