@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap");
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
/* font-family: "Rubik", serif; */
/* font-family: "Exo 2", serif; */
@media screen and (min-width: 320px) {
  .imagedesktop {
    display: none;
  }
  .cousedesktop {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .imagedesktop {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding: 0 15%;
    margin: 4% 0;
  }
  .imagedesktop img {
    width: 22%;
    object-fit: fill;
    margin: 0 1%;
    border: 1px solid rgba(128, 128, 128, 0.192);
    cursor: pointer;
    transition: 0.5s;
  }
  .imagedesktop img:hover {
    width: 22%;
    object-fit: fill;
    margin: 0 1%;
    border: 1px solid rgba(128, 128, 128, 0.192);
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.5s;
  }
  .cousemobile {
    display: none;
  }
  .cousedesktop {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
@media screen and (min-width: 1440px) {
  .imagedesktop img {
    min-width: 2rem;
    max-width: 40rem;
    object-fit: fill;
    margin: 0 1%;
    border: 1px solid rgba(128, 128, 128, 0.192);
    cursor: pointer;
    transition: 0.5s;
  }
}
