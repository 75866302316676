@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap");
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
/* font-family: "Rubik", serif; */
/* font-family: "Exo 2", serif; */

@media screen and (min-width: 320px) {
  .container {
    height: auto;
    width: 100%;
    padding: 0 5%;
  }
  .info {
    display: flex;
    margin: 20% 0;
    height: 100%;
    width: 100%;
  }

  .gridinfo {
    display: grid;
    height: 100%;
    width: auto;
    grid-template-columns: 1fr;
    padding: 0 5%;
  }
  /* Blue */
  .gridinfoshowblue {
    display: flex;
    align-items: center;
    justify-content: start;
    text-align: center;
    width: 100%;
    min-height: 50vh;
    border: 8px solid #181860;
    border-radius: 20px;
    flex-direction: column;
    position: relative;
    margin: 25% 0;
    padding: 30% 5%;

    z-index: 99;
    background-color: white;
  }
  .imagetab {
    display: none;
  }
  .gridinfoshowblue h1 {
    font-family: "Noto Sans Thai", serif;
    font-weight: 600;
    margin: 0;
  }
  .gridinfoshowblue h3 {
    margin: 7% 0;

    font-family: "Prompt", serif;
    font-weight: 600;
    font-size: 5vw;
    color: #181860;
  }
  .gridinfoshowblue p {
    display: flex;
    width: 100%;
    align-items: start;

    margin: 0;
    font-family: "Prompt", serif;
    font-weight: 400;
    font-size: 4vw;
    color: #181860;
    margin: 2% 0;
  }
  .imagegridblue {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 160px;
    width: 160px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 3px solid #181860;
    border-radius: 30px 30px 2px 30px;
    bottom: 85%;
    z-index: 99;
    background-color: #181860;
  }

  .imagegridblack {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 160px;
    width: 160px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 3px solid black;
    border-radius: 30px 30px 2px 30px;
    bottom: 85%;
    z-index: 99;
    background-color: black;
  }
  .circleborder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    border: 1px solid white;
  }
  .imagecircle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 80%;
    background-color: white;
    border-radius: 50%;
  }
  .imagecircle img {
    display: flex;
    height: auto;
    width: 65%;
    object-fit: cover;
  }
  .imagecircleblack img {
    display: flex;
    height: auto;
    width: 65%;
    object-fit: cover;
  }
  .circleborderblack {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80%;
    border-radius: 15px;
    border: 1px solid white;
  }
  .imagecircleblack {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 80%;
    background-color: white;
    border-radius: 15px;
  }
    /* Black */
  .gridinfoshowblack {
    display: flex;
    align-items: center;
    justify-content: start;
    text-align: center;
    width: 100%;
    min-height: 50vh;
    border: 8px solid black;
    border-radius: 20px;
    flex-direction: column;
    position: relative;
    margin: 25% 0;
    padding: 30% 5%;
    
    z-index: 99;
    background-color: white;
  }
  .gridinfoshowblack h1 {
    font-family: "Noto Sans Thai", serif;
    font-weight: 600;
    margin: 0;
  }
  .gridinfoshowblack h3 {
    margin: 7% 0;
    font-family: "Prompt", serif;
    font-weight: 600;
    font-size: 5vw;
    color: black;
  }
  .gridinfoshowblack p {
    display: flex;
    width: 100%;
    align-items: start;
    margin: 0;
    font-family: "Prompt", serif;
    font-weight: 400;
    font-size: 4vw;
    color: black;
    margin: 2% 0;
  }
  .iso {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .isoicon {
    min-height: 23rem;
    width: 90%;
    margin: 5% 0;
    border-radius: 15px;
    border: 1px solid rgba(128, 128, 128, 0.219);
    font-size: 90%;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    color: rgba(23, 5, 80, 1);
  }
  .isoimage {
    display: flex;
    height: 30%;
    width: 100%;
    justify-content: center;
    margin: 5% 0;
  }
  .isoimage img{
    display: flex;
    width: 40%;
    justify-content: center;
  }
  .isotextcard {
    display: flex;
    height:55%;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    line-height: 120%;
  }
  .isotextcard h2 {
    font-family: "Exo 2", serif;
    font-weight: 700;
  }
  .isotextcard p {
    font-family: "Noto Sans Thai", serif;
    font-weight: 400;
  }
  .isoicon:hover {
    transform: scale(1.1);
    transition: 0.5s;
  }
  .isotext {
    display: flex;
    height: auto;
    width: 100%;
    justify-content: center;
    margin: 5% 0;
  }
  .isotext h1 {
    font-size: 5vw;
    font-family: "IBM Plex Sans Thai", serif;
    font-weight: 700;
    color: rgba(23, 5, 80, 1);
  }
}
@media screen and (min-width: 375px) {
  .gridinfoshowblack {
    min-height: 55vh;
    width: 95%;
    border: 5px solid black;
  }
  .gridinfoshowblue {
    min-height: 55vh;
    width: 95%;
    border: 5px solid #181860;
  }
}
@media screen and (min-width: 425px) {
  .gridinfo {
    grid-template-columns: 1fr 1fr;
    padding: 0%;
    justify-content: center;
    justify-items: center;
  }
  .imagegridblue {
    height: 100px;
    width: 100px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px 20px 2px 20px;
    bottom: 85%;
  }
  .imagegridblack {
    height: 100px;
    width: 100px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px 20px 2px 20px;
    bottom: 85%;
  }
  .gridinfoshowblack {
    min-height: 35vh;
    width: 95%;
    border: 5px solid black;
    margin: 50% 0 0 0;
  }
  .gridinfoshowblue {
    min-height: 35vh;
    width: 95%;
    border: 5px solid #181860;
    margin: 25% 0;
  }
  .gridinfoshowblue h1 {
    font-family: "Noto Sans Thai", serif;
    font-weight: 600;
    margin: 0;
    font-size: 6vw;
  }
  .gridinfoshowblack h1 {
    font-family: "Noto Sans Thai", serif;
    font-weight: 600;
    margin: 0;
    font-size: 6vw;
  }
  .gridinfoshowblue h3 {
    margin: 7% 0;
    font-family: "Prompt", serif;
    font-weight: 600;
    font-size: 2.5vw;
    color: black;
  }
  .gridinfoshowblack h3 {
    margin: 7% 0;
    font-family: "Prompt", serif;
    font-weight: 600;
    font-size: 2.5vw;
    color: black;
  }
  .gridinfoshowblue p {
    display: flex;
    width: 100%;
    align-items: start;
    margin: 0;
    text-align: start;
    font-family: "Prompt", serif;
    font-weight: 400;
    font-size: 2.2vw;
    color: black;
    margin: 2% 0;
  }
  .gridinfoshowblack p {
    display: flex;
    width: 100%;
    align-items: start;
    margin: 0;
    text-align: start;
    font-family: "Prompt", serif;
    font-weight: 400;
    font-size: 2.2vw;
    color: black;
    margin: 2% 0;
  }
}

@media screen and (min-width: 535px) {
  .gridinfoshowblue {
    min-height: 50vh;
  }
  .gridinfoshowblack {
    min-height: 50vh;
  }
  .imagegridblue {
    height: 130px;
    width: 130px;
  }
  .imagegridblack {
    height: 130px;
    width: 130px;
  }
}
@media screen and (min-width: 768px) {
  .gridinfo {
    grid-template-columns: 1fr 1fr;
    padding: 0%;
  }
  .gridinfoshowblue {
    min-height: 45vh;
    width: 80%;
    border: 7px solid #181860;
    margin-left: 10%;
  }
  .gridinfoshowblack {
    min-height: 45vh;
    width: 80%;
    border: 7px solid black;
    margin-right: 10%;
  }
  .imagegridblue {
    height: 130px;
    width: 130px;
  }
  .imagegridblack {
    height: 130px;
    width: 130px;
  }

  .gridinfoshowblue h1 {
    font-family: "Noto Sans Thai", serif;
    font-weight: 600;
    margin: 0;
    font-size: 4vw;
  }
  .gridinfoshowblack h1 {
    font-family: "Noto Sans Thai", serif;
    font-weight: 600;
    margin: 0;
    font-size: 4vw;

  }
  .gridinfoshowblue h3 {
    margin: 7% 0;
    font-family: "Prompt", serif;
    font-weight: 600;
    font-size: 2vw;
    color: black;
  }
  .gridinfoshowblack h3 {
    margin: 7% 0;
    font-family: "Prompt", serif;
    font-weight: 600;
    font-size: 2vw;
    color: black;
  }
  .gridinfoshowblue p {
    display: flex;
    width: 100%;
    align-items: start;
    margin: 0;
    text-align: start;
    font-family: "Prompt", serif;
    font-weight: 400;
    font-size: 1.7vw;
    color: black;
    margin: 2% 0;
  }
  .gridinfoshowblack p {
    display: flex;
    width: 100%;
    align-items: start;
    margin: 0;
    text-align: start;
    font-family: "Prompt", serif;
    font-weight: 400;
    font-size: 1.7vw;
    color: black;
    margin: 2% 0;
  }
  .iso {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .isoicon {
    height: 30%;
    width: 50%;
    margin: 2% 0;
  }
  .isoimage {
    display: flex;
    height: 30%;
    width: 100%;
    justify-content: center;
    margin: 4%;
  }
  .isoimage img {
    display: flex;
    height: 30%;
    width: 30%;
    justify-content: center;
  }
  .isotextcard {
    display: flex;
    height: 60%;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    text-align: center;
    line-height: 120%;
   
  }
  .isotextcard h2 {
    font-family: "Exo 2", serif;
    font-weight: 700;
  }
  .isotextcard p {
    font-family: "Noto Sans Thai", serif;
    font-weight: 400;
    margin: 2% 0;
  }
  .isotext h1 {
    font-size: 4vw;
    font-family: "IBM Plex Sans Thai", serif;
    font-weight: 700;
  }
}
@media screen and (min-width: 905px) {
  .gridinfoshowblue {
    min-height: 55vh;
    width: 80%;
    border: 7px solid #181860;
    margin-left: 10%;
  }
  .gridinfoshowblack {
    min-height: 55vh;
    width: 80%;
    border: 7px solid black;
    margin-right: 10%;
  }
}
@media screen and (min-width: 1024px) {
  .container {
    height: auto;
    width: 100%;
    padding: 0 5%;
  }
  .info {
    display: flex;
    flex-direction: column;
    margin: 5% 0%;
    height: 100%;
    width: 100%;
  }
  .gridinfoshowblack {
    min-height: 50vh;
    width: 75%;
    border: 6px solid black;
    margin: 50% 0 0 0;
  }
  .gridinfoshowblue {
    min-height: 50vh;
    width: 75%;
    border: 6px solid #181860;
    margin: 25% 0;
  }
  .iso {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .isoicon {
    height: 30%;
    width: 50%;
    margin: 2% 0;
  }
  .isoimage {
    display: flex;
    height: 30%;
    width: 100%;
    justify-content: center;
    margin: 4%;
  }
  .isotext h1 {
    font-size: 3vw;
    font-family: "IBM Plex Sans Thai", serif;
    font-weight: 700;
  }
  .isotextcard h2 {
      margin:1% 0;
  }

.imagegridblue {
  position: absolute;
  bottom: 90%;
  height: 160px;
  width: 160px;
}
.imagegridblack {
  position: absolute;
  bottom: 90%;
  height: 160px;
  width: 160px;
}

}
@media screen and (min-width: 1440px) {
  .imagetab {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .imagetab img {
    max-width: 85rem;
  }
  .gridinfo {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0% 0%;
  }
  .imagegridblue {
    height: 150px;
    width: 150px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 30px 30px 2px 30px;
    top: -5rem;
  }
  .imagegridblack {
    height: 150px;
    width: 150px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 30px 30px 2px 30px;
    top: -5rem;
  }
  .gridinfoshowblack {
    min-height: 45vh;
    width: 95%;
    border: 6px solid black;
    margin: 50% 0 0 0;
  }
  .gridinfoshowblue {
    min-height: 45vh;
    width: 95%;
    border: 6px solid #181860;
    margin: 25% 0;
  }
  .gridinfoshowblue h1 {
    font-family: "Noto Sans Thai", serif;
    font-weight: 600;
    margin: 0;
    font-size: 2.5rem;
  }
  .gridinfoshowblack h1 {
    font-family: "Noto Sans Thai", serif;
    font-weight: 600;
    margin: 0;
    font-size:  2.5rem;
  }
  .gridinfoshowblue h3 {
    margin: 7% 0;
    font-family: "Prompt", serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: black;
  }
  .gridinfoshowblack h3 {
    margin: 7% 0;
    font-family: "Prompt", serif;
    font-weight: 600;
    font-size:1.5rem;
    color: black;
  }
  .gridinfoshowblue p {
    display: flex;
    width: 100%;
    align-items: start;
    margin: 0;
    text-align: start;
    font-family: "Prompt", serif;
    font-weight: 400;
    font-size: 1rem;
    color: black;
    margin: 2% 0;
  }
  .gridinfoshowblack p {
    display: flex;
    width: 100%;
    align-items: start;
    margin: 0;
    text-align: start;
    font-family: "Prompt", serif;
    font-weight: 400;
    font-size:1rem;
    color: black;
    margin: 2% 0;
  }
  .iso {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    height: auto;
    width: 100%;
  }
  .isotextcard h2 {
    margin:1% 0;
    font-size: 2rem;
}
  .isoicon {
    justify-content: start;
    min-height: 28rem;
    width: 25%;
   max-width: 35%;
    margin: 2% 2%;
    padding: 2%;
  }
  
  .isoicon img {
    height: auto;
    max-width: 10rem;
  }
  .isotext h1 {
    font-size: 2.7rem;
    font-family: "IBM Plex Sans Thai", serif;
    font-weight: 700;
  }
  .isotext {
    margin: 2% 0;
  }

  .isotextcard p{
    font-size: 1rem;
    margin: 3% 0;
    line-height: 140%;
  }
}
@media screen and (min-width: 1500px) {
  .gridinfo {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 10%;
  }
  .gridinfoshowblack {
    min-height: 10vh;
    min-width: 5rem;
    max-width: 23rem;
    border: 6px solid black;
    margin: 50% 5% 0% 5%;
  }
  .gridinfoshowblack h3{
font-size: 1.2rem;
  }
  .gridinfoshowblack p{
    font-size: 0.9rem;
      }
  .gridinfoshowblue {
    min-height: 10vh;
    min-width: 5rem;
    max-width: 23rem;
    border: 6px solid #181860;
    margin: 25% 5%;
  }
  .gridinfoshowblue h3{
    font-size: 1.2rem;
      }
      .gridinfoshowblack p{
        font-size: 0.9rem;
          }
}

