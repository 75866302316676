@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
/* font-family: "Rubik", serif; */

@media screen and (min-width: 320px) {
  .container{
    display: flex;
    height: auto;
    width: 100%;
    padding:5%;
    margin: 0;
    flex-direction: column;
  }
  .new{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    border-bottom: 1px solid rgba(128, 128, 128, 0.212);
  }
  .newtext{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 50%;
    margin: 5% 0;

  }
  .newtext img{
    height: auto;
    width: 100%;
    object-fit: fill;
  }
  .newtext h3{
    color: #BDBDBD;
    font-family: "Noto Sans Thai", serif; 
    font-weight: 400;
    font-size: auto;
  }
  .newtext h1{
    font-family: "Noto Sans Thai", serif; 
    font-weight: 700;
    font-size: auto;
    color: #333333;
  }
  .newbutton{
    display: flex;
    height: auto;
    flex-direction: row;
    width: 50%;
    justify-content: end;
    align-items: center;
  }
  .newbutton button{
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 40px;
    width: 65%;
    padding: 5%;
    border: none;
    border-radius: 5px;
    background-color: #181860;
    color: white;
    font-family: "Noto Sans Thai", serif; 
    font-weight: 300;
    font-size: 2.8vw;
  }
.newbuttontext{
  display: flex;
  width: 70%;
  height: auto;
  justify-content: center;
  align-items: center;
}
.newbuttontext p{
  margin: 0;
}
.newbuttonimg{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 30%;
}
.newbuttonimg img{
 height: 50%;
 width: 50%;
 margin-bottom: 10%;
}
  img.background{
    position: absolute;
    left: -10%;
    top: 0%;
    z-index: -1;

  }
  .couseall{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    margin: 10% 0 0 0;
  }
  .bigimage{
    height: auto;
    width: 100%;
  }
  .bigimage img{
    height: auto;
    width: 100%;
    margin: 10% 0 0 0;
  }
  .bigimage h3{
    font-family: "IBM Plex Sans Thai", serif;
    font-weight: 600;
    color: #212326;
    font-size: 1.2rem;
    margin: 0;
  }
  .couse{
    display: flex;
    flex-direction: column;
    margin:  7% 2%;
  }
  .couseshow{
    display: flex;
    width: 100%;
    height: auto;
    border-bottom: 1px solid rgba(128, 128, 128, 0.144);
    padding: 5% 0%;
  }
  .couseimage{
    display: flex;
    width: 50%;
    height: auto;
  }
  .couseimage img{
    height: 100%;
    width: 100%;
   object-fit: contain;
   border-radius: 5px;
  }
  .cousetext{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: auto;
    justify-content: center;
  }
  .cousetext h5{
    padding: 0 0 0 10%;
    font-size:3.5vw;
    margin: 0;
    font-family: "IBM Plex Sans Thai", serif;
    font-weight: 600;
    line-height: 150%;
    color: #141617;
  
  }
  .cousetext p{
    padding: 0 10%;
    font-size: 3vw;
    margin: 3% 0;
    padding: 0 0 0 10%;
    font-family: "IBM Plex Sans Thai", serif;
    font-weight: 400;
  }
}
@media screen and (min-width: 500px) {
  .cousetext h5{
    font-size: 3vw;
  }
}
@media screen and (min-width: 768px) {
  .newtext h3{
    font-size:auto;
  }
  .newtext h1{
    font-size: 6vw;
  }
  .newbutton button{
    height: 25%;
    width: 55%;
    font-size: 2vw;
    padding: 0 8%;
  }
.couseall{
  flex-direction: row;
  margin: 4% 0;
}

.couse{
  width: 60%;

}
.bigimage{
width: 100%;
height: 100%;
}
.bigimage h3{
  font-size: 1.4rem;
}
.couseimage img{
 width: 100%;
}
.cousetext h5{
  font-size: 1.2vw;
}
.cousetext p{
  font-size: 1.2vw;
}
.newbuttonimg img{
  height: auto;
  width: auto;
  margin-bottom: 10%;
 }
}
@media screen and (min-width: 1024px) {
  .container{
    padding: 0% 5%;
  }

  .newtext h3{
    font-size: auto;
  }
  .newtext h1{
    font-size:auto;
  }
  .bigimage h3{
    font-size: 1.8rem;
  }
  .newbutton button{
    height: 23%;
    width: 45%;
    font-size: 1.5vw;
    padding: 0 8%;
  }
  .bigimage{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 60%;
    justify-content: center;
  }
  .bigimage img{
    margin: 10% 0;
  }
  .cousetext{
    width: 70%;
  }
  .couseimage{
    margin: 0;
    width: 40%;
  }
  .cousetext h5{
    padding: 0 0 0 8%;
    font-size: 1.5vw;
  }
  .cousetext p{
    padding: 0 0 0 8%;
  }
}
@media screen and (min-width: 1440px) {
  .container{
    padding:0 10%;
  }
.bigimage{
  justify-content: center;
  padding-left: 5%;
}
.couseimage{
  width: 30%;
  
}
.couseimage img{
  min-width:auto;
  max-width: 20rem;
}
.bigimage img{
  min-width: 10rem;
  max-width: 40rem;
margin: 2% 0;
}
.couseall{
  margin: 0;
}
.couse{
  height: auto;
}
.cousetext h5{
  font-size: 1.4rem;
}
.cousetext p{
  font-size:1rem;
}
.newtext h3{
  font-size: 2rem;
}
.newtext h1{
  font-size: 3rem;
}
.newtext img{
  height: 3vh;
  width: 50%;
}
.newbutton button{
  height: 20%;
  width: 12rem;
  max-width: 20rem;
  font-size: 1rem;
  padding: 0 2%;
  display: flex;
}
.newbuttontext{
  display: flex;
  width: 70%;
  height: auto;
  justify-content: end;
}
img.background{
display: none;
}

}
