@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
/* font-family: "Rubik", serif; */
/* font-family: "Exo 2", serif; */

@media screen and (min-width:320px) {
    .container{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }
    .fullimage{
        display: flex;
        height:100%;
        width: 100%;
        background-size: cover; /* ปรับขนาดภาพให้ครอบคลุมพื้นที่ */
        aspect-ratio: 16/9;
        background-image: url('../image/Eng2.png');
    }
    .fullimageeng{
        display: flex;
        height:100%;
        width: 100%;
        background-size: cover; /* ปรับขนาดภาพให้ครอบคลุมพื้นที่ */
        aspect-ratio: 16/9;
        background-image: url('../image/Eng.png');
    }
   
    .containertext{
        padding: 5%;
    }
    .cousetext{
        display: flex;
        height: auto;
        width: 100%;
        background: rgb(250,250,250);
        background: linear-gradient(90deg, rgba(250,250,250,1) 0%, rgba(231,235,237,1) 100%);
    
    }
    .text{
        height: auto;
        width: 50%;
        padding: 3% 5%;
    
    }
    .text h1{
        font-family:"IBM Plex Sans Thai", serif;;
        font-weight: 800;
        font-size: 7vw;
        color: rgba(17, 2, 41, 1);
    }
    .color{
        display: flex;
        justify-content: end;
        align-items: center;
        width: 50%;
    }
    .colorright{
        height: 40%;
        width:80%;
        background: rgb(129,199,212);
    background: linear-gradient(90deg, rgba(129,199,212,1) 0%, rgba(165,219,227,1) 100%);
    
}
    

}
@media screen and (min-width:1024px) {

    .container{
        align-items: center;
    }
    .containertext{
        padding: 0 10%;
    }
    .text h1{
        font-size:2vw;
        margin: 0;
    }
    .text{
        padding: 2% 2%;
    }
    .colorright{
        height: 100%;
        width: 25%;
    }
    .fullimage{
        display: flex;
        height: 100%;
        width: 80%;
        margin: 3% 0;
        aspect-ratio: 16/7;
        background-image: url('../image/Eng2.png');
    }
    .fullimageeng{
        display: flex;
        height: 100%;
        width: 80%;
        margin: 3% 0;
        aspect-ratio: 16/7;
        background-image: url('../image/Eng.png');
    }
  .containertext{
    width: 100%;
  }

}
@media screen and (min-width:1440px) {
    .fullimage{
        display: flex;
        height: 100%;
        min-width: 10%;
        max-width: 120rem;
        margin: 3% 0;
        aspect-ratio: 16/7;
        background-image: url('../image/Eng2.png');
    }
    .fullimageeng{
        display: flex;
        height: 100%;
        min-width: 10%;
        max-width: 120rem;
        margin: 3% 0;
        aspect-ratio: 16/7;
        background-image: url('../image/Eng.png');
    }
   
    .text h1{
        font-family:"IBM Plex Sans Thai", serif;;
        font-weight: 800;
        font-size: 2.5rem;
        color: rgba(17, 2, 41, 1);
    }
    .containertext{
        display: flex;
        justify-content: center;
    }
    .cousetext{
        display: flex;
        min-height: 1rem;
        max-height: 10rem;
        min-width: 10%;
        max-width: 100rem;
        background: rgb(250,250,250);
        background: linear-gradient(90deg, rgba(250,250,250,1) 0%, rgba(231,235,237,1) 100%);
    
    }
  
  }