@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
a:focus,
a:active {
  outline: none;
  box-shadow: none;
}
.container {
  font-family: "Noto Sans Thai", serif;
  display: flex;
  height: 4rem;
  width: 100%;
  z-index: 999;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  background-color: white;
  
}
.containers {
  font-family: "Noto Sans Thai", serif;
  display: flex;
  height: 4rem;
  width: 100%;
  z-index: 999;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  background-color: white;
  justify-content: space-between;
}
.logo{
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  background-color: white;
}
.logos{
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  background-color: white;
}

.logomobile{
display: flex;
height: 100%;
width: 100%;
justify-content: center;
align-items: center;
}
.logomobile img{
  height: 60%;
  width: auto;
}
.logodesktop{
  display: none;
}

.hamberger{
  transform: rotate(0deg);
  transition: 1s;
}
.hambergeractive{
  transform: rotate(-180deg);
  transition: 1s;
}
.searhhamberger {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  width: 50%;
  z-index: 2;
  background-color: white;
}
.search {
  margin-right: 2rem;
}
.sildebar {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: -600%;
  transition: 1s;
}
.sildebaractive {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 4rem;
  transition: 1s;
}

.sildebarcontent img {
  height: 30px;
  width: 30px;
}
.sildebarcontent {
  display: flex;
  height: 3rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(172, 31, 35, 0.05);
  background-color: white;
  cursor: pointer;
  text-decoration: none;
  color: black;
}
.sildebarcontentactive {
  display: flex;
  height: 3rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid rgba(172, 31, 35, 1);
  background-color: #fff4f4;
  cursor: pointer;
  text-decoration: none;
  color: rgba(172, 31, 35, 1);
}

.sildebarcontent p {
  margin: 0 0.2rem;
}
.sildebarcontentactive p {
  margin: 0 0.2rem;
}
.languageshow{
 display: flex;
 justify-content: center;
 align-items: center;
}
.languagecard{
  display: none;
 
}
.sildebarcontent:hover .languagecard{
  display: none;
  opacity: 1;
  transition: 0.5s;
 }
@media screen and (min-width: 768px) {
  .logomobile{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: start;
    align-items: center;
    padding: 0 5%;
    }
    .logomobile img{
      height: 60%;
      width: auto;
    }
    .containers{
      justify-content: space-between;
    }
}
@media screen and (min-width: 1440px) {

    .logos{
        display: flex;
        width: 50%;
        height: 100%;
        align-items: center;
        background-color: transparent;
    }
  .logodesktop{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: start;
    align-items: center;
    }
    .logodesktop img{
      height:150%;
      width: auto;
    }
    .logomobile{
      display: none;
    }
  .container{
    height: 11vh;
    padding: 0 8%;
    background-color: white;
    transition: 0.5s;
    position: fixed;
  }
  .containers{
    height: 11vh;
    padding: 0 8%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.897);
    position: fixed;
    transition: 0.5s;
    justify-content: space-between;
  }
  .hamberger {
    display: none;
  }
  .sildebar {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
    width: 100%;
    top: 0;
    position: relative;
  }
  .sildebarcontent{
    height: 85%;
    border-bottom:3px solid transparent;
  background-color: transparent;
  position: relative;
  }

  .sildebarcontentactive{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
    color: rgba(172, 31, 35, 1);
    height: 85%;
    font-weight: 700;
    border-bottom: 3px solid rgba(172, 31, 35, 1);
  }

  .search {
    display: none;
  }

  .searhhamberger {
    width: 0;

    background-color: transparent;
  }
  
  .sildebarcontent:hover {
    background-color: transparent;
  }

  .languageshow{
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: 2%;
    cursor: pointer;
  }
 

  .languagethai{
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: 2%;
    cursor: pointer;
  }
  .languageeng{
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin:2%;
    cursor: pointer;
  }

  .languagecard{
    position: absolute;
    top: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 120%;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    width:140%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    opacity: 0;
    transition: 0.5s;
  }
  .sildebarcontent:hover .languagecard{
    opacity: 1;
    transition: 0.5s;
   }
}
