@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
/* font-family: "Rubik", serif; */
@media screen and (min-width: 320px) {
  img.background{
    display: none;
  }
.container{
  display: flex;
  flex-direction: column;
}
.aboutus{
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding: 10% 8% ;
}
.aboutus h1{
  color: #D20808;
  font-family: "Rubik", serif;
  font-weight: 700;
  font-size: auto;
  margin: 0;
  font-family:"Noto Sans Thai", serif;
}
.aboutus h3{
  color: #12295B;
  font-family: "Manrope", serif;
  font-weight: 800;
  font-size: auto;
  margin: 3% 0;
  font-family:"Noto Sans Thai", serif;
  font-weight: 700;
}
.aboutus p{
  font-family: "Noto Sans Thai", serif;
  font-weight: 500;
  font-size:16px;
  opacity: 100%;
  margin: 5% 0;
  display: -webkit-box;
  -webkit-line-clamp:6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
button.mybutton{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 45%;
  padding:4% 5%;
  border: none;
  border-radius: 5px;
  background-color: #181860;
  color: white;
  font-family: "Noto Sans Thai", serif;
  font-weight: 300;
  font-size: 0.7rem;
  margin: 5% 0;
}
.imgaboutus{
  height: auto;
  width: 100%;
  padding:0% 8% 8% 8%;
  
}
.imgaboutus img{
  height: auto;
  width: 100%;
  border-radius: 10px ;
  object-fit: cover;
}
img.colorslash{
  width: 65%;
}
}
@media screen and (min-width: 425px) {
  
  .aboutus p{
    font-size:1rem;
    line-height: 170%;
    margin: 8% 0;
  }
.imgaboutus{
  padding:0% 8% 8% 8%;
}
}
@media screen and (min-width: 550px) {
  .aboutus p{
    font-size: auto;
    line-height: 170%;
    margin: 8% 0;
  }
.imgaboutus{
  padding:0 8%;
}
button.mybutton{
  width: 35%;
  padding:3% 3%;
  font-size: 0.8rem;
  height: 15%;
}
.aboutus h1{
  font-size: auto;
}
.aboutus h3{
  font-size: auto;
}
.aboutus img{
  height: 10%;
}
img.colorslash{
  height: 3vh;
}

}
@media screen and (min-width: 768px) {

  .aboutus h1{
    font-size:3rem;
  }
  .aboutus h3{
    margin: 1% 0;
    font-size: auto;
  }
  .aboutus img{
    height: 10%;
  }
  img.colorslash{
    height: 3vh;
  }
  .aboutus p{
    font-size: auto;
    margin: 5% 0;

  }
  button.mybutton{
    width: 25%;
    padding:2% 3%;
    font-size: auto;
    margin:  0;
  }
  img.colorslash{
    width: 60%;
  }
}
@media screen and (min-width: 1024px) {
  .imgaboutus{

    padding:0% 8% 3% 8%;
  }
  .aboutus h1{
    font-size:3rem;
  }
  .aboutus h3{
  
    font-size: auto;
  }
  .aboutus img{
    height: 10%;
  }
  img.colorslash{
    height: 3vh;
  }
  .aboutus p{
    font-size: auto;
    margin: 5% 0;

  }
  button.mybutton{
    width: 23%;
    padding:2% 3%;
    font-size: 1rem;
  }
  img.colorslash{
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
.container{
  flex-direction: row;
  margin: 5% 0;
}

.aboutus{
  height: 100%;
  position: relative;
  width: 50%;
  padding: 3% 5% 5% 10%;
}
img.background{
  display: flex;
  position: absolute; 
  width: 25%;
  left: 5%;
  bottom: -60%;
  z-index: -1;
  height: 50%;

}
.imgaboutus{
  width: 50%;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
}
.imgaboutus img{
  height: auto;
 min-width: 5rem;
 max-width: 45rem;
  transition: width 0.3s ease;
}
.aboutus h1{
  font-size: 3rem;
  font-family:"Noto Sans Thai", serif;
  font-weight: 700;
}
.aboutus h3{
  font-size: 2rem;
  font-family:"Noto Sans Thai", serif;
  font-weight: 700;
}
.aboutus img{
  height: 10%;
}
img.colorslash{
  height: 3vh;
  width: 80%;
}
.aboutus p{
  margin: 5% 0;
  overflow:inherit;
}
button.mybutton{
  
  width: 30%;
  padding:0;
  font-size: 1rem;
  padding: 3% 5%;
  margin: 2% 0;
}

}
@media screen and (min-width: 1920px) {
  .imgaboutus{
    width: 50%;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
  }
  .imgaboutus img{
    width: 85%;
  }
  .aboutus h1{
    font-size:3rem;

  }
  .aboutus h3{
    font-size:auto;
  }
  .aboutus img{
    height: 10%;
  }
  img.colorslash{
    height: 3vh;
    width: 80%;
  }
  .aboutus p{
    font-size: 1rem;
    margin: 5% 0;
  
  }
  button.mybutton{
    width: 30%;
    font-size: auto;
    padding: 2.5% 6%;
    height: 10%;
  }
  img.background{
    display: flex;
    position: absolute; 
    width: 25%;
    left: 5%;
    bottom: -70%;
    z-index: -1;
    height: 50%;
  }
  .aboutus{
    position: relative;
    width: 50%;
    padding: 5% 10%;
  }
}