@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
/* font-family: "Noto Sans Thai", serif; */
/* font-family: "Inter", serif; */
/* font-family: "Manrope", serif; */
/* font-family: "IBM Plex Sans Thai", serif; */
/* font-family: "Prompt", serif; */
/* font-family: "Rubik", serif; */
@media screen and (min-width: 320px) {
  .container {
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    height: auto;
    width: 100%;
  }
  .search {
    justify-content: start;
    text-align: start;
  }
  .search h2 {
    font-family: "IBM Plex Sans Thai", serif;
    font-weight: 700;
    font-size: 6vw;
    color: #110229;
  }
  .searchicon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f4f6f9;
    border-radius: 10px;
    margin: 5% 0;
  }
  .searchicon input {
    display: flex;
    width: 85%;
    padding: 6%;
    border: none;
    background-color: #f4f6f9;
    font-family: "Noto Sans Thai", serif;
    font-size: 4vw;
    border-radius: 10px;
  }
  .searchicon::placeholder {
    color: #2d3748;
    opacity: 30%;
    font-family: "Noto Sans Thai", serif;
    font-size: 5vw;
  }
  .iconsearch {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 10%;
    padding: 2%;
    opacity: 60%;
  }
  .ontopbutton {
    display: none;
  }
  .accordianlist {
    display: none;
    height: 8vh;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 5% 0;
  }
  /* accodian อย่าแก้ */
  .accordianlist {
    display: none;
    height: 8vh;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 1rem 0;
  }
  .accordianoverflow {
    display: inline-flex;
    justify-content: space-between;
    height: 100%;
    width: 50rem;
    border-top: 1px solid rgba(128, 128, 128, 0.123);
    border-bottom: 1px solid rgba(128, 128, 128, 0.137);
  }
  .accordianoverflow button {
    height: 100%;
    width: auto;
    border: none;
    transition: 1s;
    border-top: 3px solid rgba(255, 0, 0, 0);
    background-color: transparent;
    transition: 0.5s;
    font-size: 16px;
    padding: 0 0.5rem;
    font-family: "Noto Sans Thai", serif;
    font-weight: 500;
    color: #434549;
  }
  .accordianoverflow button:hover {
    border-top: 3px solid red;
    transition: 0.5s;
    color: #ff0000;
  }

  .accordianlist::-webkit-scrollbar {
    /* delete display ของ scroll แต่ ยังใช้งานได้ */
    display: none;
  }
  .gridcontent {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    width: 100%;
    margin: 0% 0;
  }

  .img {
    display: flex;
    height: 50%;
    width: 100%;
  }
  .img img {
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
  }
  .gridcardactive {
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
    border-radius: 10px;
    background-color: #e4e4e4;
    margin: 3%;
  }
  .textcard {
    height: 100%;
    width: 100%;
    padding: 2% 4%;
  }

  .textshow h5 {
    color: #181548;
    font-family: "IBM Plex Sans Thai", serif;
    font-weight: 600;
    font-size: 5vw;
  }
  .textshow p {
    font-family: "Prompt", serif;
    font-weight: 400;
    font-size: 4vw;
    color: #3f3f3f;
    display: -webkit-box;
    padding-top: 3%;
    -webkit-line-clamp: 3; /* จำกัด 3 บรรทัด */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .cousetag {
    width: 100%;
    display: flex;
    height: auto;
    margin: 5% 0;
  }
  .cousetagtext {
    background-color: #d22329;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-family: "Noto Sans Thai", serif;
    font-weight: 300;
    font-size: 2.5vw;
    padding: 3%;
  }
  .gridcard {
    display: none;
  }
  .logo {
    display: flex;
    height: auto;
    width: 100%;
  }
  .logo img {
    width: 100%;
    object-fit: fill;
  }
  .pagination {
    display: flex;
    height: auto;
    width: 100%;
    margin: 0;
    justify-content: center;
  }
  .allnumberpage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 80%;
    margin: 5% 0;
    justify-content: space-between;
  }
  .prevnext {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Noto Sans Thai", serif;
    font-size: 3vw;
    width: 100%;
    height: 60%;
  }
  .numberpage {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-size: 3vw;
    height: 60%;
    width: 60%;
    cursor: pointer;
    background-color: transparent;
    color: #222f36;
  }
  .numberpageactive {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-size: 3vw;
    height: 65%;
    width: 40%;
    margin: 0 2%;
    padding: 5% 2%;
    background-color: #181548;
    color: white;
    cursor: pointer;
  }
}

@media screen and (min-width: 425px) {
  .container {
    padding: 5%;
  }
  .search {
    padding: 0 3%;
  }
  .numberpage {
    height: 90%;
  }
  .numberpageactive {
    padding: 5% 2%;
    border-radius: 5px;
  }

  .prevnext {
    height: 90%;
  }
  .searchicon input::placeholder {
    color: #2d3748;
    opacity: 30%;
    font-family: "Noto Sans Thai", serif;
    font-size: 4vw;
  }
  .searchicon input {
    padding: 3% 0;
    font-size: 4vw;
  }
  .gridcontent {
    grid-template-columns: 1fr 1fr;
  }
  .gridcardactive {
    border-radius: 5px;
  }
  .img img {
    border-radius: 5px 5px 0 0;
  }
  .textshow h5 {
    font-size: 2.8vw;
    margin: 0;
  }
  .textshow p {
    font-size: 2vw;
    margin: 0;
    padding-top: 3%;
  }
  .cousetagtext{
    font-size: 1.5vw;
    padding: 1% 4%;
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .textshow h5 {
    font-size: 2.5vw;
  }
  .textshow p {
    font-size: 1.5vw;
  }
  .allnumberpage {
    width: 50%;
    font-size: 1.5vw;
  }
  .prevnext {
    font-size: 1.5vw;
  }
  .numberpage {
    font-size: 1.5vw;
  }
  .numberpageactive {
    font-size: 1.5vw;
  }
  .search {
    display: flex;
    padding: 0 2%;

    flex-direction: column;
    align-items: start;
  }
  .searchicon {
    width: 100%;
    justify-content: center;
  }
  .searchicon input {
    padding: 3% 0;
    font-size: 2vw;
  }

  .searchicon input::placeholder {
    font-size: 2vw;
  }
  .searchicon input {
    padding: 3% 0;
    font-size: 2vw;
  }
  .search h2 {
    font-size: 4vw;
  }
}
@media screen and (min-width: 805px) {
  .img img {
    border-radius: 5px 5px 0 0;
  }

  .accordianlist {
    display: none;
    width: 100%;
    overflow-x: hidden;
    margin: 0 0 5% 0;
    justify-content: center;
  }
  .accordianoverflow {
    width: 100%;
  }
  .textshow h5 {
    font-size: 2.5vw;
    margin: 0;
  }
  .textshow p {
    font-size: 2vw;
    margin: 0;
  }
  .search h2 {
    font-size: 4vw;
  }
  .searchicon input {
    padding: 3% 0;
    font-size: 2vw;
  }
  .searchicon::placeholder {
    font-size: 2vw;
  }
  .cousetagtext{
    font-size: 1vw;
    padding: 1% 4%;
    text-align: center;
  }
}
@media screen and (min-width: 1024px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .search {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    margin: 3% 0;
  }
  .search h2 {
    font-size: 3vw;
  }
  .searchicon {
    width: 30%;
  }
  .search input {
    font-size: 1.5vw;
    padding: 5% 0;
  }
  .searchicon input::placeholder {
    font-size: 1.5vw;
  }
  .gridcontent {
    width: 80%;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .textshow h5 {
    font-size: 1.7vw;
    margin: 0;
  }
  .textshow p {
    font-size: 1vw;
    margin: 0;
  }

  .img img {
    border-radius: 5px 5px 0 0;
  }
  .pagination {
    justify-content: center;
  }
  .logo {
    display: none;
  }
  .allnumberpage {
    width: 40%;
  }
  .prevnext {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Noto Sans Thai", serif;
    font-size: 1.2vw;
    width: 100%;
    height: 60%;
  }
  .numberpage {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-size: 1.5vw;
    height: 60%;
    width: 70%;
    margin: 0 2%;
    cursor: pointer;
    background-color: transparent;
    color: #222f36;
  }
  .numberpageactive {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 1.5vw;
    height: 70%;
    width: 50%;
    margin: 0 2%;
    padding: 5% 2%;
    background-color: #181548;
    color: white;
    cursor: pointer;
  }
  .prevnext {
    width: 100%;
    font-size: 1.5vw;
  }
  .numberpage {
    margin: 0;
    font-size: 1.5vw;
    width: 30%;
  }
  .numberpageactive {
    width: 30%;
    font-size: 1.5vw;
  }
}
@media screen and (min-width: 1440px) {
  .accordianoverflow {
    justify-content: start;
  }
  .gridcontent {
    display: grid;
    justify-items: center;
    height: auto;
    width: 100%;
    margin: 0% 0;
    padding: 0 5%;
  }
  .gridcardactive {
    display: flex;
    flex-direction: column;
    min-height: 1rem;
    max-height: 36rem;
    min-width: 1rem;
    max-width: 30rem;
    border-radius: 10px;
    background-color: #e4e4e4;
    margin: 3%;
  }
  .accordianoverflow button {
    margin: 0 2%;
  }
  .search {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    padding: 0 7%;
  }
  .search input::placeholder {
    font-size: 1.2vw;
  }
  .search h2 {
    font-size: 3rem;
  }
  .searchicon {
    width: 30%;
  }
  .search input {
    font-size: 1vw;
    padding: 4% 0;
  }

  .allnumberpage {
    align-items: start;
    width: 40%;
  }
  .prevnext {
    width: 100%;
    font-size: 1vw;
    padding: 4% 2%;
  }

  .numberpage {
    margin: 0;
    font-size: 1vw;
    width: 30%;
    padding: 4% 2%;
  }
  .numberpageactive {
    width: 20%;
    font-size: 1vw;
    padding: 1% 3%;
  }

  .logo {
    display: none;
  }
  .textcard h5 {
    margin: 5% 0;
    font-size: 1vw;
  }
  .textcard p {
    margin: 5% 0;
    font-size: 1vw;
  }
  .textshow h5 {
    font-size: 1.5rem;
    margin: 5% 0;
  }
  .textshow p {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    -webkit-line-clamp: 4; /* จำกัด 3 บรรทัด */
    -webkit-box-orient: vertical;
  }
  .pagination {
    position: relative;
  }
  .allnumberpage {
    margin: 2% 0;
  }
  .ontopbutton {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .ontopbutton button {
    height: 6vh;
    width: 4%;
    position: absolute;
    bottom: 5%;
    right: 0%;
    font-size: 2vw;
    color: white;
    background: linear-gradient(180deg, #12285a 0%, #09072b 100%);

    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  .cousetagtext{
    font-size: 0.8rem;
    padding: 1% 4%;
    text-align: center;
  }
}

input:focus {
  outline: none;
  border: none;
}
.accordianoverflow::-webkit-scrollbar {
  /* delete display ของ scroll แต่ ยังใช้งานได้ (เปลี่ยนชื่อด้านหน้าด้วยตามclassนั้น)*/
  display: none;
}
